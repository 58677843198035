import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { generatePreSignedUrl } from '../../util/api';

import { NamedLink } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const StoreCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    store,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const id = store?.id?.uuid;

  const [image, setImage] = useState(null);
  const [fetchingImage, setFetchingImage] = useState(false);

  useEffect(() => {
    if (store?.attributes?.profile?.publicData?.businessProfilePicture && !image && !fetchingImage) {
      setFetchingImage(true);
      generatePreSignedUrl({ filename: store?.attributes?.profile?.publicData?.businessProfilePicture })
        .then(({ url }) => {
          setImage(url);
        });
    }
  }, [store]);

  let storeProps = {
    name: 'TeamPage',
    params: { id, type: 'detail' },
  };

  let { publicData } = store?.attributes?.profile || {};
  let { businessName = '' } = publicData;
  let { place_name } = publicData?.storedAddress || {};


  return (<NamedLink className={classNames(classes, css.storeCardContainer)} {...storeProps}>
      <div className={css.store}>
        <img src={image || '/static/footer-logo.png'} className={classNames(css.storeImage, {
          [css.noImage]: !image,
        })}/>
      </div>
      <div className={css.mainSectionInfo}>
        <div className={classNames(css.listingMainInfo, css.storeMainInfo)}>
          <div className={css.categoryTitle}>
            <div>
              {richText(businessName, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          {!!place_name && place_name !== '' ? <div className={css.storeAddress}>{place_name}</div> : null}
        </div>
      </div>
  </NamedLink>);
};

StoreCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

StoreCardComponent.propTypes = {
  className: string,
  rootClassName: string,
};

export default injectIntl(StoreCardComponent);
